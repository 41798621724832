import Chart from 'chart.js';

const FARGE_ARRAY = [
	'#B4E1D3',
	'#E5EEF0',
	'#FFE0B3',
	'#005366',
	'#FFEACC',
	'#4D907D',
	'#5F514A',
	'#6ECEB2',
	'#00AFD7',
	'#919290'
];

export default function initalizeSmultringdiagram() {
	const diagramSide = getNodeById('diagram-part');
	if (!diagramSide) {
		return;
	}

	const diagram = getNodeById('diagram');
	const innholdElement = getNodeById('verdier');
	const innhold = JSON.parse(innholdElement.value);
	const titler = innhold.map(element => element.tittel);
	const verdier = innhold.map(element => Number(element.verdi));

	const data = {
		labels: titler,
		datasets: [{
			label: 'Diagramverdier',
			data: verdier,
			backgroundColor: FARGE_ARRAY,
			hoverOffset: 4
		}]
	};

	const config = {
		type: 'doughnut',
		data: data,
		options: {
			cutoutPercentage: 55,
			legend: {
				position: 'right',
				labels: {
					fontSize: 18
				}
			},
			tooltips: {
				bodyFontSize: 18
			}
		}
	};

	new Chart(diagram, config);
}
