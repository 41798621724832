(() => {
	const printbuttons = [...document.$('.js-print-url')];
	printbuttons.forEach((printButton) => {
		printButton.on('click', performPrint);
	});

	function performPrint({ target }) {
		const { url } = target?.dataset ?? {};
		if (url) {
			const windowFeatures = 'left=200, top=200, width=1280, height=600, toolbar=0, resizable=0';
			const printWindow = window.open(url, '', windowFeatures);
			printWindow.addEventListener('load', () => {
				printWindow.print();
				printWindow.onafterprint = () => printWindow.close();
			}, true);
		}
		return false;
	}
})();
