export default function initalizeFritekstMedLesMer() {
	const fritekstSide = getNodeById('fritekst-med-les-mer');
	if (!fritekstSide) {
		return;
	}

	const lesMerKnapp = getNodeById('les-mer-knapp');
	const tekstKnapp = getNodeById('tekst-knapp');
	const tekst = getNodeById('les-mer-tekst');
	const ikon = getNodeById('knapp-ikon');

	if (lesMerKnapp) {
		lesMerKnapp.on('click', getLoadMoreClickHandler());
	}

	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			if (tekst.classList.contains('overflow')) {
				tekst.classList.remove('overflow');
				ikon.classList.remove('icon-chevron-ned');
				ikon.classList.add('icon-chevron-opp');
				tekstKnapp.innerHTML = 'Vis mindre';
			} else {
				tekst.classList.add('overflow');
				ikon.classList.remove('icon-chevron-opp');
				ikon.classList.add('icon-chevron-ned');
				tekstKnapp.innerHTML = 'Les mer';
			}
		};
	}
}
